
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { Dialog } from '@/types/misc';
import { MediaFile } from '@/types/media';

@Component({
  components: {
    Media: () => import('@/ui-components/Media/Media.vue'),
    IButton,
    IDialog,
  },
})
export default class MediaZoomDialog extends Vue implements Dialog {
  @Prop({ type: Object, default: () => ({}) })
  public media!: MediaFile;

  public isVideo: boolean = false;
  public isOpen = false;
  public hovered = false;

  @Watch('media', { deep: true })
  public async checkMedia() {
    this.isVideo = await this.checkMediaType();
  }

  public async mounted() {
    this.isVideo = await this.checkMediaType();
  }

  private async checkMediaType(): Promise<boolean> {
    try {
      if (this.media.path?.includes('blob')) {
        const res = await fetch(this.media?.path, { mode: 'no-cors' });
        const blob = await res.blob();
        return blob.type === 'mp4';
      }
      return this.media.path?.includes('mp4') || false;
    } catch (error) {
      return false;
    }
  }

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
