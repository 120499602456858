
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'IDialog',
})
export default class IDialog extends Vue {
  @Prop() attach!: string | Element;
  @Prop() origin!: string;
  @Prop() transition!: string;
  @Prop() contentClass!: string;
  @Prop() maxWidth!: number | string;
  @Prop() maxHeight!: number | string;
  @Prop({ type: Boolean }) hideOverlay!: boolean;
  @Prop({ type: Boolean }) fullscreen!: boolean;
  @Prop({ type: Boolean }) persistent!: boolean;
  @Prop({ type: Boolean }) value!: boolean;

  @Emit('input')
  public update(value: boolean) {
    return value;
  }

  @Emit('click:outside')
  public clickOutside(value: boolean) {
    return value;
  }
}
