
import IButton from '@/ui-components/IButton/IButton.vue';
import MediaZoomDialog from '@/dialogs/shared/MediaZoomDialog.vue';
import { MediaFile } from '@/types/media';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'Media',
  components: {
    IButton,
    MediaZoomDialog,
  },
})
export default class Media extends Vue {
  @Prop({ type: Object, default: () => ({}) }) media!: MediaFile;
  @Prop({ type: Boolean, default: false }) public playable!: boolean;
  @Prop({ type: Boolean, default: false }) public zoomable!: boolean;
  @Prop({ type: Boolean, default: false }) public showAllContent!: boolean;
  @Prop({ type: Boolean, default: false }) public videoOpacity!: boolean;
  @Prop({ type: Boolean, default: false }) public dimensions!: boolean;
  @Prop({ type: Boolean, default: false }) public showUploadedBy!: boolean;

  public isVideo: boolean = false;
  public landscape: boolean = false;
  public error: boolean = false;
  public isPlaying: boolean = false;
  public hovered = false;

  @Watch('media', { deep: true })
  public async checkMedia() {
    this.isVideo = await this.checkMediaType();
  }

  private async checkMediaType(): Promise<boolean> {
    try {
      if (this.media.path?.includes('blob')) {
        const res = await fetch(this.media?.path, { mode: 'no-cors' });
        const blob = await res.blob();
        return blob.type === 'mp4';
      }
      return this.media.path?.includes('mp4') || false;
    } catch (error) {
      return false;
    }
  }

  private calculateElementAspectRatio(element: HTMLElement) {
    try {
      const { clientWidth, clientHeight } = element as HTMLElement;
      return clientWidth / clientHeight;
    } catch {
      return 1;
    }
  }

  public togglePlay() {
    const video = this.$refs.video as HTMLVideoElement;
    if (video) {
      if (this.isPlaying) {
        return video.pause();
      }
      return video.play();
    }
  }

  public async checkForShowAllContent(mediaEl: HTMLElement) {
    try {
      const parentEl = mediaEl.parentElement?.parentElement;
      const { showAllContent } = this;

      if (parent && showAllContent) {
        const {
          clientHeight: parentHeight,
          clientWidth: parentWidth,
        } = parentEl!;
        const { clientHeight: mediaHeight, clientWidth: mediaWidth } = mediaEl;
        const mediaAspectRatio = this.calculateElementAspectRatio(mediaEl);
        if (mediaHeight > parentHeight) {
          mediaEl.style.height = `${parentHeight}px`;
          mediaEl.style.width = `${parentHeight * mediaAspectRatio}px`;
        }

        if (mediaWidth > parentWidth) {
          mediaEl.style.height = `${parentHeight / mediaAspectRatio}px`;
          mediaEl.style.width = `${parentWidth}px`;
        }
      }
    } catch (err) {
      // Handle error here
    }
  }

  public async checkMediaOrientation(event: any) {
    try {
      const [mediaElement] = event.path;
      const mediaContailerElement = this.$refs.mediaContent as HTMLElement;

      const mediaAspectRatio = this.calculateElementAspectRatio(mediaElement);
      const mediaContainerAspectRatio = this.calculateElementAspectRatio(
        mediaContailerElement
      );

      this.landscape = Boolean(mediaAspectRatio > mediaContainerAspectRatio);
      this.$nextTick(() => {
        this.checkForShowAllContent(mediaElement);
      });
    } catch (err) {
      this.landscape = false;
    }
  }

  public openMediaZoomDialog() {
    const dialog = this.$refs.mediaZoomDialogRef as MediaZoomDialog;
    dialog.open();
  }

  public get resolution(): string {
    const { width, height, unit } = this.media;

    return `${this.$t('width')} ${width} x ${this.$t('height')} ${height} ${unit}`;
  }

  public async mounted() {
    await this.checkMedia();
  }

}
